<template>
  <div>
    <table-overview
      store="subscriptions"
      title="Subscriptions"
      icon="far fa-fw fa-bell"
      :headers="[
        { text: 'Id', value: 'id' },
        { text: 'Incident', value: 'incident', sortable: false },
        { text: 'Permission', value: 'permission', sortable: false },
        { text: 'Roles', value: 'roles', sortable: false },
        { text: 'Users', value: 'created_at', sortable: false }
      ]"
      force-initial-sort
    >
      <subscriptions-table-row
        slot="table-row"
        slot-scope="{ item }"
        :subscription-id="item"
      />
      <subscriptions-table-filter slot="filters" />
    </table-overview>
  </div>
</template>

<script type="text/babel">
import subscriptionsTableRow from "@/components/subscription/SubscriptionTableRow";
import subscriptionsTableFilter from "@/components/subscription/SubscriptionTableFilter";
import TableOverview from "@/components/generic/overview/TableOverview";
import subscriptionsModule from "@/store/modules/subscriptions";
import { mapOverviewLifecycleMethods } from "@/views/helpers";

const overviewLifecycleMethods = mapOverviewLifecycleMethods(
  "subscriptions",
  subscriptionsModule
);
import store from "@/store";

export default {
  name: "subscriptions-overview",
  components: {
    TableOverview,
    subscriptionsTableFilter,
    subscriptionsTableRow
  },
  ...overviewLifecycleMethods,
  metaInfo: () => ({
    title: `subscriptions`
  }),
  methods: {
    markAllAsRead() {
      store.dispatch("subscriptions/markAllAsRead");
    }
  }
};
</script>
