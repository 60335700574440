<template>
  <tr>
    <td>{{ subscription.id }}</td>
    <td>{{ subscription.machine_name }}</td>
    <td>{{ subscription.permission.name }}</td>
    <td>
      <div :key="roleIndex" v-for="(role, roleIndex) in subscription.roles">
        <v-chip primary label>{{ role.name }}</v-chip>
        <v-chip :key="index" v-for="(user, index) in role.users">
          <v-avatar>
            <v-icon>account_circle</v-icon>
          </v-avatar>
          {{ user }}
        </v-chip>
      </div>
    </td>
    <td>
      <v-chip :key="index" v-for="(user, index) in subscription.users">
        <v-avatar>
          <v-icon>account_circle</v-icon>
        </v-avatar>
        {{ user }}
      </v-chip>
    </td>
  </tr>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "vuex";
import { FIND_BY_ID } from "@/store/templates/table/getter-types";

const { mapGetters } = createNamespacedHelpers("subscriptions");
export default {
  name: "subscriptions-table-row",
  components: {},
  props: {
    subscriptionId: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapGetters([FIND_BY_ID]),
    subscription() {
      return this[FIND_BY_ID](this.subscriptionId);
    }
  },
  methods: {}
};
</script>
