<template>
  <v-form> </v-form>
</template>
<script type="text/babel">
import { createNamespacedHelpers } from "@/store/helpers";

const { mapComputedFilters } = createNamespacedHelpers("subscriptions");

export default {
  name: "subscriptions-table-filter",
  components: {},
  computed: {
    ...mapComputedFilters([])
  }
};
</script>
