import { FETCH as DISPATCH_FETCH } from "@buldit/fe-common/src/store/templates/table/action-types";
import tableActions from "@buldit/fe-common/src/store/templates/table/actions";
import api from "@/api/subscription";

export default {
  ...tableActions,
  async [DISPATCH_FETCH](context) {
    return await tableActions.fetch(context, { fetchCall: api.fetchAll });
  }
};
