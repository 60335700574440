import Vue from "vue";
import {
  normalize as internalNormalize,
  subscription as schema
} from "@/store/schema";
import { createApiUrl } from "../url";

const resourceUrl = createApiUrl("api/v1/subscriptions");

async function fetchAll({ filters, pagination } = {}) {
  const url = createApiUrl(resourceUrl, {
    filters,
    includes: fetchAllIncludes,
    pagination
  });
  const { data } = await Vue.$http.get(url);
  const { entities, result } = normalize(data.data, schema);

  return {
    result,
    entities,
    meta: data.meta,
    links: data.links
  };
}

function normalize(data) {
  return internalNormalize(data, schema);
}

const fetchAllIncludes = [];

export default {
  normalize,
  fetchAll
};
