var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('table-overview',{attrs:{"store":"subscriptions","title":"Subscriptions","icon":"far fa-fw fa-bell","headers":[
      { text: 'Id', value: 'id' },
      { text: 'Incident', value: 'incident', sortable: false },
      { text: 'Permission', value: 'permission', sortable: false },
      { text: 'Roles', value: 'roles', sortable: false },
      { text: 'Users', value: 'created_at', sortable: false }
    ],"force-initial-sort":""},scopedSlots:_vm._u([{key:"table-row",fn:function(ref){
    var item = ref.item;
return _c('subscriptions-table-row',{attrs:{"subscription-id":item}})}}])},[_c('subscriptions-table-filter',{attrs:{"slot":"filters"},slot:"filters"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }